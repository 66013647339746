:root{
  --navigation-link:#767A7B;
  --navigation-hover:#29325B;
  --transition-duration:0.3s;
  --transition-delay:0.1s;
  --navigation-bg:#283258;
  --background-side-bar-menu:#2D2F57;
  --dropdown-color:#808080;
  --about-heading:#007581;
}
/* config elemnts  */
#root{
  overflow: hidden;
}
.bg-color-change{
  background-color: var(--navigation-hover);
}
.justify-text{
  text-align: justify;
}
.about-p-width{
  width: 100%;
  max-width: 216px;
}

@font-face {
  font-family: 'recoleta-bold';
  src: url(./assets/fonts/Recoleta-Bold.ttf);
}
@font-face {
  font-family: 'recoletaalt-medium';
  src: url(./assets/fonts/RecoletaAlt-Medium.ttf);
}
@font-face {
  font-family: 'recoletaalt-regular';
  src: url(./assets/fonts/RecoletaAlt-Regular.ttf);
}
@font-face {
  font-family: 'souvenir-medium';
  src: url(./assets/fonts/suvenn.ttf);
}
@font-face {
  font-family: 'gesstwo';
  src: url(./assets/fonts/GE\ SS\ Two\ Bold.otf);
}
@font-face {
  font-family: 'futuralt-book';
  src: url(./assets/fonts/FuturaLT-Book.ttf);
}
@font-face {
  font-family: 'futuralt';
  src: url(./assets/fonts/FuturaLT.ttf);
}
@font-face {
  font-family: 'futuralt-book-oblique';
  src: url(./assets/fonts/FuturaLT-BookOblique.ttf);
}
@font-face {
  font-family: 'futuralt-bold';
  src: url(./assets/fonts/FuturaLT-Bold.ttf);
}
@font-face {
  font-family: 'recoletaalt-bold';
  src: url(./assets/fonts/RecoletaAlt-Bold.ttf);
}
@font-face {
  font-family: 'futuralt-light';
  src: url(./assets/fonts/FuturaLT-Light.ttf);
}
@font-face {
  font-family: 'recoleta-black';
  src: url(./assets/fonts/Recoleta-Black.ttf);
}
@font-face {
  font-family: 'recoletalt-light';
  src: url(./assets/fonts/RecoletaAlt-Light.ttf);
}
@font-face {
  font-family: 'recoletalt-black';
  src: url(./assets/fonts/RecoletaAlt-Black.ttf);
}
@font-face {
  font-family: 'ge-ss-light';
  src: url(./assets/fonts/GE_SS_Two_Light.otf);
}


/* end config elements */



/* over elements  */
.rafeek-heading-img{
  width: 30px;
}

.container{
  width: 90% !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media (min-width: 960px){
  .container{
    max-width: 90% !important;
  }
}

/* end over elements  */



/* HEADER  */


.rafeek-header-logo{
  width: 265px !important;
}
.button-wrapper button{
  background-image: url(./assets/image/button.png);
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: 'recoleta-bold';
  background-position: top center;
  font-size: 12pt;
}
.button-wrapper button a{
  font-size: 14px;
}
.rafeek-mobile-logo{
  width: 40px;
}
.menu-mobile{
  width: 40px;
}


/* END HEADER  */



/* NAVIGATION  */


.main-navigation-link{
  font-family: 'recoletaalt-medium';
  font-size: 20pt;
  color: var(--navigation-link) !important;
  cursor: pointer;
}
.main-navigation-link:hover{
  font-size: 20pt;
  font-family: 'recoleta-bold';
  color: var(--navigation-hover) !important;
  transition-duration: var(--transition-duration);
  transition-delay: var(--transition-delay);
}
.child-navigation-wrapper{
  width: 170px;
  white-space: nowrap;
}



/* END NAVIGATION  */


/* Side bar menu  */


.side-menu-header{
  background-color: var(--background-side-bar-menu);
}
.side-menu-header h5{
  font-family: 'recoleta-bold';
  font-size: 30pt;
  color: white;
}
.rafeek-sidemenu{
  border-bottom: .5px solid #999999;
  margin-bottom: 20px;
}
.rafeek-sidemenu h3{
  font-family: 'recoleta-bold';
  font-size: 20pt;
  color: var(--background-side-bar-menu);
}
.rafeek-sidemenu a{
  text-decoration: none;
  font-family: 'recoleta-bold';
  font-size: 20pt;
  color: var(--background-side-bar-menu);
}


/* end sidebar menu  */



/* SLIDER  */


  /* slider image 1 */
.slider-image1-wrapper{
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
}
.slider-image-wrapper{
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
}
.button-slider1{
  position: absolute;
  bottom: 10%;
  left: 10%;
}
.button-slider-btn-1{
  background-image: url(./assets//image//buttongreen.png);
  width: 124px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: cover;
}
.button-slider-btn-2,.button-slider-btn-3{
  background-image: url(./assets//image//buttongreen.png);
  width: 156px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
}
.button-slider-btn-2 span,.button-slider-btn-3 span{
  font-family: "souvenir-medium";
  font-size: 14pt;
  color: white;
}
.home-slider .swiper-pagination-bullets{
  bottom: 30px !important;
}
.home-slider .swiper-pagination-bullets .swiper-pagination-bullet{
  width: 10px;
  height: 10px;
  border: 2px solid white !important;
  opacity: unset !important;
  background-color: transparent;
}
.home-slider .swiper-pagination-bullet-active{
  background-color: white !important;
}

  /* slider IMAGE 2 */
.button-slider2{
  position: absolute;
  top: 10%;
  left: 10%;
}
.button-slider2-btn-1,.button-slider2-btn-2,.button-slider2-btn-3{
  background-image: url(./assets//image//buttongreen.png);
  width: 156px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
}
.button-slider2-btn-1 span,.button-slider2-btn-2 span,.button-slider2-btn-3 span{
  font-family: "gesstwo";
  font-size: 14pt;
  color: white;
}

  /* slide image 3  */
  .button-slider3{
    position: absolute;
    bottom: 20%;
    right: 10%;
  }
  .button-slider3-btn-1,.button-slider3-btn-2,.button-slider3-btn-3{
    background-image: url(./assets//image//buttongreen.png);
    width: 156px;
    height: 34px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .button-slider3-btn-1 span{
    font-family: 'recoletaalt-medium';
    font-size: 14pt;
    color: white;
  }
  .button-slider3-btn-2 span,.button-slider3-btn-3 span{
    font-family: 'souvenir-medium';
    font-size: 14pt;
    color: white;
  }


/* END SLIDER  */


/* ABOUT RAFEEK  */


.about-rafeek-heading h1{
  font-family: 'souvenir-medium';
  font-size: 40pt;
  color: var(--about-heading);
  position: relative;
}
.about-rafeek-heading h1::before{
  content: '';
  position: absolute;
  height: 50px;
  width: 36px;
  background-image: url(./assets//icon/abouticon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  right: -34%;
  display: none;
}
.about-rafeek-image{
  width: 120px;
  max-width: unset !important;
}
.about-rafeek-heading div p{
  margin-top: -10px;
  font-size: 8pt;
  font-family: 'souvenir-medium';
  color: var(--about-heading);
  line-height: 1;
}
.about-rafeek-heading div p strong{
  font-family: 'recoleta-bold';
  font-size: 8pt;
}
.rafeek-about-second-p{
  line-height: 1;
  font-size: 8pt;
  font-family: 'futuralt-book';
  color: #666666;
}
.rafeek-about-second-p strong{
  font-family: 'futuralt';
}
.rafeek-about-third-p{
  font-size: 8pt;
  line-height: 1;
  font-family: 'futuralt-book-oblique';
  color: #666666;
}
.rafeek-about-icon{
  position: absolute;
  width: 80%;
  top: 0;
  left: -18%;
  z-index: -1;
}
/* END ABOUT RAFEEK  */


/* NOS SERVICE  */

.services-text{
  color: var(--navigation-hover);
}
.services-text h2{
  font-family: 'futuralt-bold';
  font-size: 16pt;
}
.services-text h3{
  font-family: 'futuralt-bold';
  font-size: 12pt;
  padding-left: 5%;
  margin-top: -10px;
}
.services-text h4{
  font-family: 'recoleta-bold';
  font-size: 26pt;
  padding-left: 8%;
  margin-top: -10px;
  white-space: nowrap;
  position: relative;
}
.services-text h4::before{
  content: '';
  position: absolute;
  background-image: url(./assets/icon/service-icon.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 66px;
  width: 90px;
  bottom: -112%;
  left: 3%;
  z-index: 2;
}
.service-child{
  margin-top: 10px !important;
  overflow: hidden;
}
.first-service{
  margin-top: 40px !important;
}
.service-btn-1{
  position: absolute;
  right: 0;
  bottom: 5%;
  background-image: url(./assets/image/service-btn.png);
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  background-color: transparent;
  font-family: 'recoletaalt-bold';
  font-size: 7pt;
  color: white;
  padding-right: 20px;
  padding-top: 4px;
}
.service-btn-2{
  position: absolute;
  right: 0;
  bottom: 5%;
  background-image: url(./assets/image/service-btn-2.png);
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  background-color: transparent;
  font-family: 'recoletaalt-bold';
  font-size: 7pt;
  color: white;
  padding-right: 20px;
  padding-top: 4px;
}
.service-btn-3{
  position: absolute;
  right: 0;
  bottom: 5%;
  background-image: url(./assets/image/service-btn-3.png);
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  background-color: transparent;
  font-family: 'recoletaalt-bold';
  font-size: 7pt;
  color: white;
  padding-right: 20px;
  padding-top: 4px;
}
.last-service-text{
  position: absolute;
  bottom: 0;
  right: -25%;
  white-space: nowrap;
  font-family: 'recoletaalt-bold';
  color: var(--navigation-hover);
  font-size: 8pt;
}
.last-service-description{
  font-size: 5pt;
  line-height: 1;
  font-family: 'futuralt-book';
  margin-bottom: 5px;
  width: 100%;
}
.adjust-text{
  width: 104%;
}

/* END NOS SERVICE  */


/* NOTRE PROCESSUS */
.processus-icon{
  position: absolute;
  width: 300px;
  height: 600px;
  right: -60%;
  top: 2%;
}
.processus-icon-desk{
  position:absolute;
  top: -70%;
  width: 700px;
  right: -25%;
  z-index: -1;
}
.notre-processus-h2{
  font-size: 20pt;
  font-family: 'recoleta-bold';
  color: var(--navigation-hover);
  position: relative;
  white-space: nowrap;
}
.notre-processus-h2::after{
  content: '';
  position: absolute;
  height: 26px;
  width: 40px;
  background-image: url(./assets/icon/processus-icon.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;

}
.cercle{
  height: 500px;
  width: 500px;
  border: 5px solid var(--navigation-hover);
  border-radius: 50%;
  margin-top: 90px;
}
.cercle div{
  cursor: pointer;
}
.cercle-child-1{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: white;
  width: 80px;
}
.cercle-child-1 span{
  font-size: 80pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
}
.cercle-child-1 p{
  white-space: nowrap;
  margin-top: -34px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
  font-size: 14pt;
}
.cercle-child-2{
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%,-50%);
  font-size: 80pt;
  background-color: white;
  border-radius: 50%;
}
.cercle-child-2 span{
  font-size: 80pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
}
.cercle-child-2 p{
  white-space: nowrap;
  margin-top: -34px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
  font-size: 14pt;
}
.cercle-child-3{
  position: absolute;
  top: 82%;
  left: 86%;
  transform: translate(-50%,-50%);
  font-size: 80pt;
  background-color: white;
  font-size: 80pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
  border-radius: 100%;
}
.cercle-child-4{
  position: absolute;
  top: 82%;
  left: 14%;
  transform: translate(-50%,-50%);
  font-size: 80pt;
  background-color: white;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
  border-radius: 100%;
  width: 80px;
}
.cercle-child-5{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%,-50%);
  font-size: 80pt;
  background-color: white;
}
.cercle-child-5 span{
  font-size: 80pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
}
.cercle-child-5 p{
  white-space: nowrap;
  margin-top: -34px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
  font-size: 14pt;
}
.cercle-child-6{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 80pt;
  background-color: white;
  font-family: 'futuralt-bold';
  color: var(--navigation-hover);
  border-radius: 100%;
  width: 80px;
}
.cercle-child-6 span{
  font-size: 80pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
}
.cercle-child-6 p{
  white-space: nowrap;
  margin-top: -34px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
  font-size: 14pt;
}
.cercle-child-text-3{
  position: absolute;
  top: 98%;
  left: 84%;
  transform: translate(-50%,-50%);
  font-size: 14pt;
  background-color: white;
  white-space: nowrap;
  padding-left: 5px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
}
.cercle-child-text-4{
  position: absolute;
  top: 98%;
  left: 16%;
  transform: translate(-50%,-50%);
  font-size: 14pt;
  background-color: white;
  white-space: nowrap;
  padding-left: 5px;
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
}
.cercle-child-clicked-text{
  background-color: #007583;
  position: absolute;
  top: 10%;
  left: 105%;
  transform: translate(-50%,-50%);
  width: 350px;
}
.cercle-child-clicked-text p{
  font-family: 'futuralt-book';
  color: white;
}
.processus-mobile{
  border-left: 2px solid var(--navigation-hover);
  padding-top: 50px;
}
.processus-mobile-number h6{
  font-size: 60pt;
  font-family: 'futuralt-bold';
  color: white;
  -webkit-text-stroke-color: var(--navigation-hover);
  -webkit-text-stroke-width: 3px;
  background-color: white;
}
.processus-mobile-number h3{
  font-family: 'recoletaalt-medium';
  color: var(--navigation-hover);
  font-size: 8pt;
}
.processus-mobile-desc{
  display: none;
}
.processus-mobile-desc p{
  background-color: #007583;
  color: white;
  font-size: 8pt;
  font-family: 'futuralt-book';
}
/* processus active  */

.processus-active .processus-mobile-number{
  position: absolute;
  left: -9%;
}
.processus-active .process-child-first-h3{
  margin-left: -10px;
}
.processus-active .process-child-h3{
  margin-left: 4px;
  width: 80px;
}
.processus-mobile-component-wrapper{
  justify-content: space-between ;
}
.processus-active{
  justify-content: end ;
  transition-duration: var(--transition-duration);
  transition-delay: var(--transition-delay);
}
.processus-active .processus-mobile-desc{
  display: unset;
}
.processus-active p{
  width: 105px;
  font-size: 5pt;
}
.fade-in{
  transition-duration: var(--transition-duration);
  transition-delay: var(--transition-delay);
}
/* END NOTRE PROCESSUS  */


/* RAFEEK SOCIAL  */

.rafeek-social-tele{
  position: absolute;
  top: 78%;
  left: 24%;
  font-size: 4pt;
}
.rafeek-social-appelez-nous{
  font-family: 'futuralt-light';
  font-size: 6pt;
}
.rafeek-social-phone{
  font-family: 'futuralt-bold';
  font-size: 6pt;
}
.rafeek-social-media{
  position: absolute;
  top: 78%;
  right: 15%;
}
.rafeek-social-suivez-nous{
  font-family: 'futuralt-bold';
  font-size: 6pt;
}
.rafeek-social-media-accounts a img{
  width: 10px;
  height: 10px;
}
.rafeek-social-media-accounts a{
  display: flex;
  text-align: start;
}

/* END RAFEEK SOCIAL  */

/* CONTACT SECTION  */
.contact-wrapper h2{
  font-family: 'recoletaalt-bold';
  font-size: 20pt;
  color: white;
  white-space: nowrap;
}
.contact-section-wrapper{
  background-color: var(--navigation-hover);
}
.contact-heading-icon{
  width: 40px;
}

.contactez-nous-aside{
  background-color: #F0EFEE;
  height: 800px;
  border-radius: 30px;
}
.contactez-nous-aside h3{
  font-family: 'recoletaalt-bold';
  color: var(--navigation-hover);
  font-size: 20pt;
  white-space: nowrap;
}
.contactez-nous-aside p{
  font-family: 'futuralt';
  white-space: nowrap;
  font-size: 8pt;
}
.contactez-nous-coordonne{
  background-color: #F0EFEE;
  height: 550px;
  border-radius: 30px;
  max-width: 700px !important;
}
.contactez-nous-coordonne h3{
  font-family: 'recoletaalt-bold';
  color: var(--navigation-hover);
  font-size: 30pt;
}
.input-checkbox{
  width: 20px;
  height: 20px;
}
.contactez-nous-coordonne form div p{
  font-family: 'futuralt-book';
  font-weight: bold;
}
.contactez-nous-coordonne form div label{
  font-family: 'futuralt-book';
  font-weight: bold;
}
.contact-mobile-formulaire label{
  font-family: 'futuralt-book';
  font-weight: bold;
}
.contact-form-inputs{
  border-radius: 10px;
  border: 1px solid #BEC0C0;
  outline: none;
}
.pre-maroc-number{
  width: 50px;
  font-size: 14px;
}
.send-form-btn{
  background-color: #007482;
  color: white;
  font-size: 10pt;
  font-family: 'recoleta-black';
  width: 38%;
  border-radius: 15px;
  margin-top: 9rem !important;
}
.rafeek-contact-img {
  position: absolute;
  bottom: -1.8%;
  right: 6%;
  width: 220px;
}
/* END CONTACT SECTION  */


/* NOUS REJOINDRE  */
.nous-rejoindre-wrapper{
  padding-top: 30px;
  background-color: var(--navigation-hover);
}
.nous-rejoindre-heading{
  font-size: 24pt;
  font-family: 'recoleta-bold';
  color: white;
  white-space: nowrap;
}
.nous-rejoindre-p{
  font-size: 7pt;
  font-family: 'recoletalt-light';
  color: white;
  position: relative;
}
.nous-rejoindre-p::before{
  content: '';
  position: absolute;
  background-image: url(./assets/icon/rejoindre-icon.svg);
  background-repeat: no-repeat;
  height: 22px;
  width: 8px;
  left: -4%;
  top: 10%;
}
.nous-rejoindre-h3{
  color: white;
  font-family: 'recoletalt-black';
  font-size: 15pt;
  white-space: nowrap;
}
.nous-rejoindre-form label{
  color:white;
  font-size: 8pt;
  font-family: 'futuralt-book';
}
.nous-rejoindre-form div{
  justify-content: flex-end;
}
.nous-rejoindre-form .send-form-btn{
  justify-content: center !important;

}
.nous-rejoindre-form button{
  margin-top: 2rem !important;
  width: 80% !important;
  font-size: 12pt;
}
.rejoindre-checkbox{
  cursor: pointer;
  border-radius: 20px;
  width: 12px;
  height: 20px;
}
.line-icon{
  width: 1px;
  height: 30px;
}
.nous-rejoindre-form .form-button-wrapper{
  justify-content: center !important;
}

/* END NOUS REJOINDRE  */

/* HOME VIDEO  */

.home-vide-bg{
  background-color: var(--navigation-hover);
  padding-top: 80px;
}
.home-video-wrapper h2{
  font-family: 'ge-ss-light';
  color: white;
  font-size: 22pt;
  white-space: nowrap;
}
.home-video-wrapper h3{
  font-family: 'gesstwo';
  color: white;
  font-size: 22pt;
  white-space: nowrap;
  z-index: 2;
}
.play-video-icon{
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}
.travaillez-chez-rafeek{
  width: 100%;
  margin-top: 100px;
  border-bottom: 1px solid white;
}
.travaillez-chez-rafeek img{
  width: 60%;
}
/* END HOME VIDEO  */



/* footer  */
.footer-wrapper{
  background-color: var(--navigation-hover);
}
.footer-logo{
  width: 34px;
}
.footer-links a{
  text-decoration: none;
  margin-right: 15px;
}
.footer-links a img{
  width: 20px;
}


/* MEDIA QUERIES */
@media (min-width: 300px) {
  .processus-active p{
    width: 140px;
    font-size: 5pt;
  }
  .processus-icon{
    position: absolute;
    width: 340px;
    height: 600px;
    right: -60%;
    top: 2%;
  }
}
@media (min-width: 360px) {
  .processus-active p{
    width: 180px;
    font-size: 6pt;
  }
  .contact-wrapper h2{
    font-size: 24pt;
  }
  .contactez-nous-aside h3{
    font-size: 26pt;
  }
  .contactez-nous-aside p{
    font-size: 10pt;
  }
  .notre-processus-h2{
    font-size: 24pt;
  }
  .processus-icon{
    position: absolute;
    width: 400px;
    height: 600px;
    right: -60%;
    top: 2%;
  }
  .processus-2-mobile{
    font-size: 6.5pt;
  }
  .processus-3-mobile{
    font-size: 6.5pt !important;
  }
  .processus-4-mobile{
    font-size: 6.9pt !important;
  }
}
@media (min-width: 500px){
  .processus-icon {
    position: absolute;
    width: 400px;
    height: 600px;
    right: -30%;
    top: 2%;
  }
  .about-p-width{
    width: 100%;
    max-width: 200px;
  }

}
@media (min-width: 576px) {
  .services-text h4::before{
    height: 57px;
    width: 90px;
    bottom: -88%;
    left: 4%;
  }
  .last-service-text{
    font-size: 16pt;
  }
  .last-service-description{
    font-size: 8pt;
  }
  .nous-rejoindre-p{
    font-size: 12pt;
  }
  .about-rafeek-heading h1{
    font-size: 50pt;
  }
  .about-rafeek-heading h1::before {
    content: '';
    position: absolute;
    height: 83px;
    width: 60px;
    background-image: url(./assets/icon/abouticon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    right: -32%;
  }
  .about-rafeek-image{
    width: unset;
    max-width: 100% !important;
  }
  .about-rafeek-heading div p{
    font-size: 8pt;
  }
  .about-rafeek-heading div p strong{
    font-size: 8pt;
  }
  .rafeek-about-second-p{
    font-size: 8pt;
  }
  .rafeek-about-third-p{
    font-size: 8pt;
  }
  .about-rafeek-image{
    height: 284px !important;
  }
  .about-p-width{
    width: 100%;
    max-width: 266px;
  }
}
@media (min-width: 768px) {
  .button-wrapper button a{
    font-size: 22px;
  }
  .adjust-text{
    width: unset;
  }
  .button-wrapper button{
    background-size: contain;
    font-size: 20pt;
  }
  .about-rafeek-heading div p{
    font-size: 12pt;
  }
  .about-rafeek-heading h1{
    font-size: 84pt;
  }
  .about-rafeek-heading div p strong{
    font-size: 12pt;
  }
  .rafeek-about-second-p{
    font-size: 11pt;
  }
  .rafeek-about-third-p{
    font-size: 11pt;
  }
  .about-rafeek-heading h1::before{
    height: 186px;
    width: 135px;
    right: -36%;
  }
  .about-rafeek-image{
    height: 400px !important;
  }
  /* Nos services  */
  .services-text h2{
    font-size: 34pt;
  }
  .services-text h3{
    font-size: 24pt;
    padding-left: 3%;
  }
  .services-text h4{
    font-size: 64pt;
    padding-left: 6%;
  }
  .services-text h4::before{
    height: 184px;
    width: 214px;
    bottom: -132%;
    left: 1%;
  }
  .service-child{
    padding-left: 10% !important;
  }
  .first-service{
    margin-top: unset !important;
  }
  .service-btn-1{
    font-size: 16pt;
  }
  .service-btn-2{
    font-size: 16pt;
  }
  .service-btn-3{
    font-size: 16pt;
  }
  .last-service-text{
    font-size: 20pt;
  }
  .last-service-description{
    font-size: 10pt;
  }
  .last-service-description{
    margin-bottom: 15px;
  }
  /* processu  */
  .notre-processus-h2{
    font-size: 50pt;
  }
  .notre-processus-h2::after{
    height: 42px;
    width: 62px;
  }
  .cercle-child-clicked-text{
    width: 250px;
    left: 95%;
  }
  .cercle-child-clicked-text p{
    font-size: 7pt;
  }
  .rafeek-social-tele{
    font-size: 8pt;
  }
  /* end processus  */
  .contact-wrapper h2{
    font-size: 50pt;
  }
  .contact-heading-icon{
    width: 90px;
  }
  .rafeek-contact-img {
    width: 340px;
  }
  .contactez-nous-aside{
    height: 1000px;
  }
  .send-form-btn{
    font-size: 20pt;
    width: 36%;
    margin-top: 12rem !important;
  }
  .contactez-nous-aside p{
    font-size: 15pt;
  }
  .contactez-nous-aside h3{
    font-size: 30pt;
  }
  .rafeek-social-appelez-nous{
    font-family: 'futuralt-light';
    font-size: 10pt;
  }
  .rafeek-social-phone{
    font-family: 'futuralt-bold';
    font-size: 10pt;
  }
  .rafeek-social-suivez-nous{
    font-size: 10pt;
  }
  .rafeek-social-media-accounts a img{
    width: 20px;
    height: 20px;
  }
  /* rejoindre  */
  .nous-rejoindre-wrapper{
    padding-top: 100px;
  }
  .nous-rejoindre-heading{
    font-size: 50pt;
  }
  .nous-rejoindre-p{
    font-size: 15pt;
  }
  .nous-rejoindre-p::before{
    width: 22px;
  }
  .nous-rejoindre-h3{
    font-size: 20pt;
  }
  .nous-rejoindre-form button{
    font-size: 20pt;
  }
  .nous-rejoindre-form label{
    font-size: 14pt;
  }
  .rejoindre-checkbox{
    width: 20px;
  }
  .home-video-wrapper h2{
    font-size: 30pt;
  }
  .home-video-wrapper h3{
    font-size: 30pt;
  }
  .play-video-icon{
    width: 150px;
    height: 150px;
  }
  /* FOOTER  */
  .footer-logo{
    width: 50px;
  }
  .footer-links a{
    margin-right: 20px;
    font-size: 15pt;
  }
  .footer-links a img{
    width: unset;
  }
  .about-p-width{
    width: 90%;
    max-width: 420px;
  }
  .rafeek-social-tele{
    font-size: 8pt;
  }
  .rafeek-social-suivez-nous{
    font-size: 8pt;
  }
  .rafeek-heading-img{
    width: 62px;
  }
}
@media (min-width: 992px) {
  .rafeek-navigation{
    padding-left: 10%;
    padding-right: 10%;
  }
  .button-slider1{
    bottom: 25%;
  }
  .button-slider-btn-1{
    width: 232px;
    height: 50px;
  }
  .button-slider-btn-2,.button-slider-btn-3{
    width: 400px;
    height: 88px;
  }
  .button-slider-btn-2 span,.button-slider-btn-3 span{
    font-size: 38pt;
  }
  /* Slider image 2 */
  .button-slider2-btn-1{
    width: 232px;
    height: 50px;
  }
  .button-slider2-btn-2,.button-slider2-btn-3{
    width: 400px;
    height: 88px;
  }
  .button-slider2-btn-1 span{
    font-size: 22pt;
  }
  .button-slider2-btn-2 span,.button-slider2-btn-3 span{
    font-size: 38pt;
  }
  /* slide image 3  */
  .button-slider3-btn-1{
    width: 232px;
    height: 50px;
  }
  .button-slider3-btn-2,.button-slider3-btn-3{
    width: 400px;
    height: 88px;
  }
  .button-slider3-btn-1 span{
    font-size: 24pt;
  }
  .button-slider3-btn-2 span,.button-slider3-btn-3 span{
    font-size: 38pt;
  }

  /* rafeek about  */
  .rafeek-about-wrapper{
    padding-left: 10%;
    padding-right: 10%;
  }
  .about-rafeek-heading h1{
    font-size: 84pt;
  }
  .about-rafeek-heading div p{
    font-size: 11pt;
  }
  .about-rafeek-heading div p strong{
    font-size: 11pt;
  }
  .rafeek-about-second-p{
    font-size: 11pt;
  }
  .rafeek-about-third-p{
    font-size: 11pt;
  }
  .about-rafeek-heading h1::before{
    height: 160px;
    width: 116px;
    right: -42%;
  }
  .about-rafeek-image{
    height: 400px !important;
  }
  /* Nos services  */
  .services-text h2{
    font-size: 30pt;
  }
  .services-text h3{
    font-size: 20pt;
  }
  .services-text h4{
    font-size: 60pt;
  }
  .services-text h4::before{
    height: 180px;
    width: 200px;
    left: 2%;
  }
  .last-service-description{
    font-size: 8pt;
    width: 95%;
  }
  /* processus  */
  .notre-processus-h2{
    font-size: 60pt;
  }
  .notre-processus-h2::after{
    height: 54px;
    width: 80px;
  }
  .cercle-child-clicked-text{
    width: 350px;
    left: 105%;
  }
  .cercle-child-clicked-text p{
    font-size: 9.5pt;
  }
  .rafeek-social-tele{
    font-size: 10pt;
  }
  .rafeek-social-media-accounts a img{
    width: 20px;
    height: 20px;
  }
  .rafeek-social-suivez-nous{
    font-size: 10pt;
  }
  /* end processus  */
  .contact-wrapper h2{
    font-size: 60pt;
  }
  .contact-heading-icon{
    width: 90px;
  }
  .contactez-nous-aside p{
    font-size: 10pt;
  }
  .contactez-nous-coordonne{
    height: 640px;
  }
  .contactez-nous-aside{
    height: 640px;
  }
  .rafeek-social-appelez-nous{
    font-family: 'futuralt-light';
    font-size: 14pt;
  }
  .rafeek-social-phone{
    font-family: 'futuralt-bold';
    font-size: 14pt;
  }
  .rafeek-contact-img {
    position: absolute;
    bottom: -2.5%;
    left: 1%;
    width: 276px;
  }
  .send-form-btn{
    font-size: 24pt;
    width: 60%;
    margin-top: 5rem !important;
  }
  .nous-rejoindre-h3{
    font-size: 30pt;
  }
  .nous-rejoindre-heading{
    font-size: 60pt;
  }
  .nous-rejoindre-p{
    font-size: 18pt;
  }
  .nous-rejoindre-form{
    width: 590px;
  }
  /* home video  */
  .home-video-wrapper h2{
    font-size: 45pt;
  }
  .home-video-wrapper h3{
    font-size: 45pt;
  }
  .processus-icon-desk{
    position:absolute;
    top: -70%;
    width: 600px;
    right: -32%;
    z-index: -1;
  }
  
}
@media (min-width: 1200px) {
  .rafeek-navigation{
    padding-left: 15%;
    padding-right: 15%;
  } 
  .about-rafeek-heading h1::before{
    right: -16%;
  }
  .services-text h4::before{
    bottom: -140%;
    left: 3%;
  }
  .service-child{
    padding-left: 10% !important;
  }
  .last-service-description{
    font-size: 14pt;
    width: 95%;
  }
  .rafeek-social-tele{
    font-size: 18pt;
  }
  .rafeek-social-media-accounts a img{
    width: 30px;
    height: 30px;
  }
  .rafeek-social-suivez-nous{
    font-size: 18pt;
  }
  /* Contacte  */
  .contactez-nous-aside{
    height: 700px;
  }
  .contactez-nous-coordonne{
    height: 700px;
  }
  .nous-rejoindre-p{
    font-size: 20pt;
  }
  .about-rafeek-heading div p{
    font-size: 15pt;
  }
  .about-rafeek-heading h1{
    font-size: 106pt;
  }
  .about-rafeek-heading div p strong{
    font-size: 15pt;
  }
  .rafeek-about-second-p{
    font-size: 14pt;
  }
  .rafeek-about-third-p{
    font-size: 14pt;
  }
  .about-rafeek-heading h1::before{
    height: 206px;
    width: 150px;
    right: -42%;
  }
  .about-rafeek-image{
    height: 500px !important;
  }
  .processus-icon-desk{
    position:absolute;
    top: -70%;
    width: 630px;
    right: -25%;
    z-index: -1;
  }
  .rafeek-heading-img{
    width: 76px;
  }
  .about-p-width {
    width: 92%;
    max-width: 530px;
  }
}
@media (min-width: 1400px) {
  .rafeek-navigation{
    padding-left: 25%;
    padding-right: 25%;
  }
  .services-text h4::before{
    left: 4%;
  }
  .last-service-description{
    font-size: 16pt;
    width: 95%;
  }
  .rafeek-contact-img {
    position: absolute;
    bottom: -3%;
    left: -5%;
    width: 330px;
  }
  .about-rafeek-heading div p{
    font-size: 18pt;
  }
  .about-rafeek-heading h1{
    font-size: 130pt;
  }
  .about-rafeek-heading div p strong{
    font-size: 18pt;
  }
  .rafeek-about-second-p{
    font-size: 16pt;
  }
  .rafeek-about-third-p{
    font-size: 16pt;
  }
  .about-rafeek-heading h1::before{
    height: 258px;
    width: 187px;
    right: -44%;
  }
  .about-rafeek-image{
    height: 100% !important;
  }
  .processus-icon-desk{
    position:absolute;
    top: -70%;
    width: 700px;
    right: -25%;
    z-index: -1;
  }
  .rafeek-heading-img{
    width: 100px;
  }
  .about-p-width {
    width: 100%;
    max-width: 700px;
  }
}

/* END MEDIA QUERIES */
@media (prefers-color-scheme:dark){
  body{
    background-color: black !important;
  }
  .cercle-child-1{
    background-color: black !important;
  }
  .cercle-child-2{
    background-color: black !important;
  }
  .cercle-child-3{
    background-color: black !important;
  }
  .cercle-child-4{
    background-color: black !important;
  }
  .cercle-child-5{
    background-color: black !important;
  }
  .cercle-child-6{
    background-color: black !important;
  }
  .cercle-child-text-3{
    background-color: black !important;
  }
  .cercle-child-text-4{
    background-color: black !important;
  }
  .processus-mobile-number h6{
    background-color: black !important;
  }
}


/* .processus-2-mobile{
  font-size: 6.5pt !important;
}
.processus-3-mobile{
  font-size: 6.5pt !important;
}
.processus-4-mobile{
  font-size: 6.9pt !important;
} */

.processus-ip-desk{
  font-size: 11pt;
}
.processus-number{
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'futuralt-bold';
  color: white;
  padding-right: 2%;
  padding-bottom: 1%;
}
.processus-number-mb{
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'futuralt-bold';
  color: white;
  padding-right: 4%;
  padding-bottom: 1%;
  font-size: 10px;
}
.youtube-video-wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.bg-video{
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: black;
  opacity: .5;
}
.youtube-video-wrapper .youtube-video{
  height: 40%;
  width: 90%;
  z-index: 6;
}
.youtube-video-wrapper iframe{
  width: 100%;
  z-index: 6;
}
@media (min-width: 992px) {
  .youtube-video-wrapper .youtube-video{
    height: 90%;
  }
}

.submit-status-css{
  position: fixed !important;
  top: 20%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: 'futuralt-book';
}
.submit-file-styling{
  border: 1px dashed white;
  height: 200px;
  font-size: 14px !important;
}
.file-delete-styling{
  position: absolute;
  right: 2%;
  top: 4%;
  font-size: 12px;
  cursor: pointer;
}
.file-delete-styling:hover{
  border-bottom: 1px solid rgba(var(--bs-danger-rgb),var(--bs-text-opacity));
}
.file-error-styling{
  font-size: 14px;
}